<template>
  <div class="form--group amenity--section row">
    <label class="col-12 col-lg-3">Fasilitas</label>
    <div class="col-12 col-lg-6">
      <facility-with-unit-field
        v-for="facility in facilitiesWithUnit"
        :key="facility.id"
        :name="facility.name"
        :checked="facility.checked"
        :id="facility.id"
        :uomName="facility.uom_name"
        :amount="facility.amount"
        ref="facilityWithUnitRef"
      />
      <facility-without-unit-field
        v-for="facility in facilitiesWithoutUnit"
        :key="facility.id"
        :name="facility.name"
        :checked="facility.checked"
        :id="facility.id"
      />
    </div>
  </div>
</template>

<script>
const FacilityWithUnitField = () =>
  import('@/components/project-management/unit-type/form/facilities/facility-with-unit-field');
const FacilityWithoutUnitField = () =>
  import('@/components/project-management/unit-type/form/facilities/facility-without-unit-field');
import { mapGetters } from 'vuex';

export default {
  name: 'facilities-section',
  components: {
    FacilityWithUnitField,
    FacilityWithoutUnitField,
  },
  computed: {
    ...mapGetters({
      facilitiesWithUnit: 'unitType/form/facilitiesWithUnit',
      facilitiesWithoutUnit: 'unitType/form/facilitiesWithoutUnit',
    }),
  },
};
</script>

<style scoped></style>
